<style lang="scss" scoped>
.m_textover { word-break: break-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.bdr-8{border-radius: 8px}
.c-level-1{color: #FF002B;}
.c-level-0{color: #FFA019;}
.color-blue{color: #007EFF;}
.cursor-p{cursor: pointer;}
.wrap{
    display: flex;background-color: #F5F7FA;padding:8px 0  16px;
    .main{
        width: calc(100% - 336px);height: 100%;margin: 0 16px;
        .banner img{width: 100%;height: 100%;}
    }
    .sidebar{
        background-color: #fff;width:288px;margin-right: 16px;padding:0 16px;
    }
}
.header{
    display: flex;justify-content: space-between;height: 24px;padding: 20px 20px 12px;
    span:first-child{color: #000;}
    span:last-child{color: #000;font-size: 12px;color: #666;line-height: 24px;}
}
// .main

.nav{
    background-color: #fff;margin: 16px 0;border-radius: 8px;
    .nav-content{
        display: flex;margin-top: 24px;padding:0px 20px 20px;
        li{
            flex: 1; height: 92px;display: flex;flex-direction: column;text-align: center;font-size: 14px;color: #333;
            img{width: 64px; height: 64px;margin: 0 auto;margin-bottom: 6px;}
            &:hover{
                img{transform: translateY(-14px);}
            }
        }
    }
}
.new{
    display: flex;
    .news-conent{
        width: calc(50% - 8px);background-color: #fff;min-height:446px;
        &:first-child{margin-right: 16px;}
        li{
            height: 40px;line-height: 40px;padding: 0 20px;font-size: 14px;display: flex;justify-content: space-between;margin-bottom:12px;
            &:hover{background-color: #F5F7FA;}
        }
        .new-content{
           width: calc(100% - 44px);display: inline-block;
        }
    }
}
/deep/ {
    .el-carousel__indicators--horizontal {
        position: absolute; bottom: 10px; text-align: right;
        button { width: 6px; height: 6px; background: #0D0D0D; border-radius: 50%; opacity: 0.5;}
        & .is-active button { width: 24px; height: 8px; background: #ffffff; opacity: 0.5; border-radius: 10px;}
    }
    .el-carousel__arrow{ background-color: rgba(0,0,0,0.4);width: 40px;height:40px}
}
//.sidebar
.calendar {
    width: 248px;font-size: 14px;
    /deep/ {
        .el-calendar__header {
            padding:16px 0;border:none;position: relative;justify-content: center;
            .el-button-group {
                &>.el-button:nth-child(2) {display: none;}
                &>.el-button:nth-child(1), &>.el-button:nth-child(3) {
                    position: absolute;left:10px;top:14px;border: none; font-size: 0;
                    width: 24px; height: 24px; background: #F3F4F6; border-radius: 4px;overflow: hidden;
                    &:after {
                        content: '\e610';font-family: iconfont;font-size: 12px;transform: rotate(180deg);display: block; color:#858585;
                        position: relative; top: -1px; left: 4px;
                    }
                }
                &>.el-button:nth-child(3) {
                    right:10px;left:auto;
                    &:after {transform: rotate(0);left: -5px;}
                }
            }
        }
        .el-calendar__body {
            padding:0;
            .el-calendar-table td {border-radius: 50%;}
            .el-calendar-table td.is-selected {
                background: #007EFF;color:#fff;
                .el-calendar-day {background: none;}
            }
        }
        .el-calendar-day {height: 35px;}
        .el-calendar-table td {border: none;text-align: center;}
    }
}
.sidebar-title{padding: 16px 0;}
.schedule{
    li{
        height: 22px;line-height: 22px;font-size: 14px;margin-bottom:22px;color:#333;
        span:first-child{display: inline-block;width: 8px;height: 8px;border-radius: 50%;}
        span:last-child{color: #666;}
        .finsh{background-color: #66CC00;}
        .unfinsh{background-color: #ccc;}
        .schedule-date{margin: 0 12px 0 8px;}
        &:last-child{margin-bottom: 0;}
    }
}
</style>

<template>
    <div class="wrap">
       <div class="main">
            <el-carousel  :height="getBannerHeight" trigger="click" class="banner">
                <el-carousel-item v-for="(img,i) in imgs" :key="i">
                    <img :src="img" alt="" srcset="" class="bdr-8" >
                </el-carousel-item>
            </el-carousel>
            <div class="nav bdr-8">
                <div class="header">
                    <span >快捷入口</span>
                    <span class="cursor-p">查看更多 ></span>
                </div>
                <ul class="nav-content">
                    <li v-for="item in nav" :key="item.title">
                        <img :src="item.src" alt="" srcset="">
                        <span>{{item.title}}</span>
                    </li>
                </ul>
            </div>
            <div class="new">
                <div class="news-conent bdr-8">
                    <div class="header ">
                        <span>消息中心&nbsp;({{newCenter.length}})</span>
                        <span class="cursor-p">查看更多 ></span>
                    </div>
                    <ul>
                        <li v-for="(c,index) in newCenter" :key="index">
                            <span class="m_textover new-content">
                                <span class="new-label" :class="c.level === 0 ? 'c-level-0':'c-level-1'">{{c.label}}：</span>
                                <span> {{c.content}}</span>
                            </span>
                            <span class="color-blue cursor-p">处理</span>
                        </li>
                    </ul>
                </div>
                <div class="news-conent bdr-8">
                    <div class="header">
                        <span>系统消息&nbsp;({{newSystem.length}})</span>
                        <span class="cursor-p">查看更多 ></span>
                    </div>
                    <ul>
                        <li v-for="(c,index) in newSystem" :key="index">
                            <span class="m_textover new-content">
                                <span class="new-label" :class="c.level === 0 ? 'c-level-0':'c-level-1'">{{c.label}}：</span>
                                <span> {{c.content}}</span>
                            </span>
                            <span class="color-blue cursor-p">处理</span>
                        </li>
                    </ul>
                </div>
            </div>
       </div>
       <div class="sidebar">
            <el-calendar v-model="curDate" class="calendar"></el-calendar>
            <div class="sidebar-title">日程</div>
            <ul class="schedule">
                <li v-for="(s,index) in schedule" :key="index" >
                    <span :class="s.finsh ? 'finsh' : 'unfinsh'"></span>&nbsp;
                    <span class="schedule-date">{{s.date}}</span>
                    <span class="s-content">{{s.content}}</span>
                </li>
            </ul>
       </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            imgs: [
                'https://sta.youxin.cloud/test/tmp/20220725/16587229178849892.png',
                'https://sta.youxin.cloud/test/tmp/20220725/16587229470249404.png',
                'https://sta.youxin.cloud/test/tmp/20220725/16587229741420238.png',
                'https://sta.youxin.cloud/test/tmp/20220725/16587229744540425.png',
                'https://sta.youxin.cloud/test/tmp/20220725/16587229746482669.png'
            ],
            nav: [
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587231956067471.png', title:'订单管理'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587230398031624.png', title:'线索库'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587230397003694.png', title:'数据统计'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587231958672457.png', title:'话术库'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587230399073812.png', title:'销售机会'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587231961067204.png', title:'海报库'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587231956890414.png', title:'跟进记录'},
                {src:'https://sta.youxin.cloud/test/tmp/20220725/16587230395878818.png', title:'课程中心'},
            ],
            newCenter:[
                {label:'待办逾期提醒',level:1, content:'为客户发送促销活动推送马上逾期请尽快处理'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（2个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
            ],
            newSystem:[
                {label:'节点逾期提醒',level:0, content:'红灯节点（2个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'待办逾期提醒',level:1, content:'为客户发送促销活动推送马上逾期请尽快处理'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
                {label:'节点逾期提醒',level:0, content:'红灯节点（23个）、黄灯节点（18个）、请查阅'},
            ],
            schedule:[
                {finsh: true, date: '10:30',content :'部门会议'},
                {finsh: true, date: '11:00',content :'迭代需求设计资源'},
                {finsh: false, date: '14:40',content :'后台首页方案确认'},
                {finsh: false, date: '16:30',content :'后台设计优化方向沟通'},
                {finsh: false, date: '17:00',content :'客户项目实施协助'},
            ],
            curDate: new Date(),
        }
    },

    created() {

    },
    components: {},

    computed:{
        getBannerHeight() {
            console.log('%c [  ]-160', 'font-size:13px; background:pink; color:#bf2c9f;', window.screen.availWidth-(208+288+32-13) )
            let height = (window.screen.availWidth-(208+288+32-13) )* 0.225
            console.log('%c [ height ]-162', 'font-size:13px; background:pink; color:#bf2c9f;', height)
            return String(height)
        }
    },

    methods: {}
}

</script>